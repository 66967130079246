<template>
  <div class="modal-ticket-comment">
    <esmp-modal
      v-model="show"
      title="Редактирование"
      :width="800"
      ok-text="Да"
      cancel-text="Нет"
      class-name="modal-ticket-comment"
      @on-ok="$emit('set-edit', true)"
    >
      <p class="rate-text">
        Вы уверены, что хотите перевести заявку в режим редактирования? <br>
        В случае подтверждения все согласования будут запущены заново
      </p>
    </esmp-modal>
  </div>
</template>

<script>

export default {
  name: 'ModalEditTicket',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show: {
      get() {
        return this.isShow;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: this.$options.name, newValue });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.rate-text {
  font-size: 20px;
}
</style>
